html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7fc;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"), local("Product Sans"),
    url("./assets/fonts/Product\ Sans\ Regular.ttf") format("truetype");
}

.button-hover:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.slack-code-block {
  --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  font-family: Monaco, Menlo, Consolas, Courier New, monospace !important;
  font-size: 16px;
  line-height: 1.50001;
  font-variant-ligatures: none;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
}

.slack-code {
  padding: 2px 3px 1px;
  border: 1px solid var(--saf-0);
  border-radius: 3px;
  background-color: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  color: #e01e5a;
}

.no-default-link,
.no-default-link:hover {
  color: inherit;
  text-decoration: none;
}

.landing-integration-icon-container {
  height: 200px;
  width: 200px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px;
  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-integration-icon-container img {
  height: 80%;
  width: 80%;
}
